import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import { Icon } from '@iconify/react';
import { API_BASE_URL } from '../../config/serverApiConfig';
import { Modal, Button } from 'react-bootstrap';
import * as apiService from '../../services';

const Login = () => {
  const [data, setData] = useState({ user: "", password: "" });
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  useEffect(() => {
    const registerButton = document.getElementById('reg');
    if (registerButton) {
      registerButton.style.display = 'none';
    }
  }, []);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      const url = API_BASE_URL + "/sbuserlogin";
      const { data: res } = await axios.post(url, data);

      // ADAPTATION for the new response:
      // The API returns: { Admin_Status: bool, Message: "...", user_data: [...] }
      // "user_login" is no longer present. Instead, check if "Message" = "User Login Successful"
      if (res.Message === "User Login Successful") {
        // Use a placeholder token to mimic successful login
        window.localStorage.setItem("token", "validUser");
        
        window.localStorage.setItem('user_data', JSON.stringify(res.user_data[0]));
        window.localStorage.setItem("adminid", res.user_data[0].adminid);
        window.localStorage.setItem("admin_type", res.user_data[0].admin_type);
        window.localStorage.setItem("adminemail", res.user_data[0].adminemail);

        // Redirect as before
        window.location = "/partners";
      } else {
        setError(res.Message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
    }
  };

  const [showChangePassword, setShowChangePassword] = useState(false);
  const [email, setNewemail] = useState('');
  const handleCloseChangePassword = () => {
    setShowChangePassword(false);
  };
  const handleChangePassword = () => {
    setShowChangePassword(true);
  };

  const [changePasswordmsg, setchangePasswordmsg] = useState('');

  const handlePasswordChange = async () => {
    try {
      const response = await apiService.forgetPassword(email);
      console.log(response);
      setchangePasswordmsg(response.Message);

      const sendmail = `<body style="font-family:Arial,sans-serif;background-color:#f4f4f4;margin:0;padding:0">
        <div class="container" style="width:70%;margin:30px auto;background:#fff;padding:20px;box-shadow:0 0 15px rgba(0,0,0,.2);border-radius:8px;text-align:center">
          <div class="header" style="margin-bottom:20px">
            <img src="https://starbehaviors.com/uploadimage/star_behaviors_llc_cover.jpeg" alt="Company Logo" style="width:auto;height:100px;">
          </div>
          <div class="content" style="text-align:left;line-height:1.6">
            <h4 style="margin-top:0">Your Password Reset Information</h4>
            <p style="font-size:16px;">Dear ${response.name},</p>
            <p style="font-size:16px;">
              Your password for Star Behaviors has been reset as requested today. Below are your new login details:
              <br><b>Username:</b> ${response.email},
              <br><b>Password:</b> ${response.tempPassword}
            </p>
            <p style="font-size:16px;">Please log in to your Star Behaviors account using the following link:</p>
            <p style="font-size:16px;">
              <a href="https://sb.starbehaviors.com/" style="color:#06c;text-decoration:none">Star Behaviors Login</a>
            </p>
            <p style="font-size:16px;">
              If you did not initiate this password reset request, please contact our support team immediately at 
              <a href="mailto:support@starbehaviour.com" style="color:#06c;text-decoration:none">support@starbehaviour.com</a>.
            </p>
            <p style="font-size:16px;">Thank you for choosing Star Behaviors!<br>Best regards,</p>
          </div>
          <div class="footer" style="margin-top:20px;font-size:.9em;color:#555">Star Behaviors Support</div>
        </div>
      </body>`;

      const requestBody = {
        email: email,
        name: response.name,
        subject: "Your Password Reset Information",
        message: sendmail
      };

      const mailApiUrl = "https://bakemywords.com/mailchimp/sendmail.php";
      // Using no-cors mode as in your original code
      const fetchResponse = await fetch(mailApiUrl, {
        method: "POST",
        mode: "no-cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody)
      });
      // no-cors -> can't parse response
      // Just log for debugging
      console.log("Mail API call sent.");
    } catch (error) {
      console.error('Error changing password:', error);
    }
  };

  return (
    <>
      <div className="position-relative d-flex flex-column justify-content-center align-items-center py-3 px-2">
        <div className="admin-login-page">
          <div className="card">
            <h4 className="card-header text-uppercase">Admin Login</h4>
            <div className="card-body">
              {showChangePassword && (
                <Modal show={showChangePassword} onHide={() => setShowChangePassword(false)} centered>
                  <Modal.Header closeButton>
                    <Modal.Title>Forgot Password</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <label>Email</label>
                    <br />
                    <input
                      className="form-control"
                      placeholder="Enter Your Email"
                      type="email"
                      value={email}
                      onChange={(e) => setNewemail(e.target.value)}
                    />
                    <span style={{ color: "red" }}>{changePasswordmsg}</span>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowChangePassword(false)}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handlePasswordChange}>
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}

              <form onSubmit={handleSubmit}>
                <div className="form-floating mb-3">
                  <input
                    id="emailAddress"
                    className="form-control"
                    type="email"
                    name="user"
                    onChange={handleChange}
                    value={data.user}
                    required
                    placeholder="Email Address"
                  />
                  <label htmlFor="emailAddress">Email Address</label>
                </div>
                <div className="input-group mb-3">
                  <div className="form-floating flex-fill">
                    <input
                      id="password"
                      className="form-control"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onChange={handleChange}
                      value={data.password}
                      required
                      placeholder="Password"
                    />
                    <label htmlFor="password">Password</label>
                  </div>
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={handleTogglePasswordVisibility}
                  >
                    <Icon icon={showPassword ? "oi:eye" : "oi:eye"} />
                  </button>
                </div>
                <div>
                  {error && <div className={styles.error_msg}>{error}</div>}
                  <button type="submit" className="btn btn-primary">
                    Login
                  </button>
                  <button className="btn btn-primary mx-2" type="button" onClick={handleChangePassword}>
                    Forgot Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
