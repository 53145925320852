import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from "../styles.module.css";
import { Icon } from '@iconify/react';
import { API_BASE_URL } from '../../../config/serverApiConfig';
import { Link, useNavigate } from "react-router-dom";

function RegistrationForm() {
  const [formErrors, setFormErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const [countryValue, setCountryValue] = useState("");
  const [stateValue, setStateValue] = useState({});
  const [stateData, setStateData] = useState("");
  const [cityValue, setCityValue] = useState({});
  const [cityData, setCityData] = useState("");
  const [cityFromZip, setCityFromZip] = useState('');

  // NEW STATE for handling incorrect user scenario (for the modal)
  const [isIncorrectUser, setIsIncorrectUser] = useState(false);

  const handleChange = (e) => {
    setCountryValue(e.target.value);
  };

  const handleStateChange = (e) => {
    setStateData(e.target.value);
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleLogin1 = () => {
    navigate('/landingpage/login');
  };

  function checkAndEnableButton() {
    const button = document.getElementById("save");
    if (button && button.disabled) {
      button.disabled = false;
    }
  }

  useEffect(() => {
    setInterval(checkAndEnableButton, 3000);
    
    // ALWAYS update the header image on reload
    const imageFile = localStorage.getItem("patnerImg");
    const reg = localStorage.getItem("reg");
    const div = document.getElementById('banding');
    if (div) {
      // Remove any existing image
      div.innerHTML = "";
      // Create a new image element
      var img = document.createElement('img');
      img.src = 'https://starbehaviors.com/uploadimage/' + imageFile;
      img.width = 136;
      img.height = 48;
      img.alt = 'Star Behaviors Logo';
      img.id = 'bandingImg';
  
      var anchor = document.createElement('a');
      anchor.href = 'https://sb.starbehaviors.com/landingpage/' + reg;
      anchor.appendChild(img);
  
      div.appendChild(anchor);
    }

    var div1 = document.getElementById('st');
    var imgEl = document.getElementById('imgId');
    if (div1 && imgEl) {
      div1.removeChild(imgEl);
    }

    var div2 = document.getElementById('register');
    var button = document.getElementById('reg');
    if (div2 && button) {
      div2.removeChild(button);
      var newButton = document.createElement('button');
      newButton.className = 'nav-link btn btn-link text-white';
      newButton.textContent = 'Login';
      newButton.id = 'reg';
      newButton.addEventListener('click', handleLogin1);
      div2.appendChild(newButton);
    }

    const raw = JSON.stringify({
      country: countryValue,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    fetch("https://casaahaanahotels.com/ttjstatelist", requestOptions)
      .then((response) => response.text())
      .then((data) => {
        setStateValue(JSON.parse(data));
      })
      .catch((error) => console.log("error", error));
  }, [countryValue]);

  useEffect(() => {
    const raw = JSON.stringify({
      state: stateData,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    fetch("https://casaahaanahotels.com/ttjcitylist", requestOptions)
      .then((response) => response.text())
      .then((data) => {
        setCityValue(JSON.parse(data));
      })
      .catch((error) => console.log("error", error));
  }, [stateData]);

  const joblistcity = (e) => {
    setCityData(e.target.value);
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [lastSegment, setLastSegment] = useState('');
  useEffect(() => {
    const pathname = window.location.pathname;
    const segments = pathname.split('/');
    const segment = segments[segments.length - 1];
    setLastSegment(segment);
  });

  // NEW: Call sb_getlandingpage in the register page to handle local storage & incorrect user
  useEffect(() => {
    let segment = lastSegment;
    // If segment is blank, use default usercode
    if (!segment) {
      segment = '22c4693b-c80a-4785-82ef-08e0faa905b1';
    }
    // If localStorage has 'reg' and it's different, clear it
    if (localStorage.getItem('reg') && localStorage.getItem('reg') !== segment) {
      localStorage.removeItem('patnerImg');
      localStorage.removeItem('partnerurl');
      localStorage.removeItem('companyname');
      localStorage.removeItem('reg');
    }

    axios
      .post(API_BASE_URL + '/sb_getlandingpage', {
        usercode: segment ? segment : '22c4693b-c80a-4785-82ef-08e0faa905b1'
      })
      .then(response => {
        if (response.data.Message && response.data.Message === 'Incorrect User ') {
          localStorage.removeItem('patnerImg');
          localStorage.removeItem('partnerurl');
          localStorage.removeItem('companyname');
          localStorage.removeItem('reg');
          setIsIncorrectUser(true);
        }
        else if (response.data.user_data && response.data.user_data.length > 0) {
          const userData = response.data.user_data[0];

          // Remove existing partner items
          localStorage.removeItem('patnerImg');
          localStorage.removeItem('partnerurl');
          localStorage.removeItem('companyname');

          // Store new items from API
          localStorage.setItem('patnerImg', userData.imageFile);
          localStorage.setItem('partnerurl', userData.admin_site_url);
          localStorage.setItem('companyname', userData.companyname);
          localStorage.setItem('reg', userData.admincode);

          setIsIncorrectUser(false);
        }
        else {
          localStorage.removeItem('patnerImg');
          localStorage.removeItem('partnerurl');
          localStorage.removeItem('companyname');
          localStorage.removeItem('reg');
          setIsIncorrectUser(true);
        }
      })
      .catch(error => {
        console.error(error);
        localStorage.removeItem('patnerImg');
        localStorage.removeItem('partnerurl');
        localStorage.removeItem('companyname');
        localStorage.removeItem('reg');
        setIsIncorrectUser(true);
      });
  }, [lastSegment]);

  const [formData, setFormData] = useState({
    emailAddress: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    middleName: '',
    lastName: '',
    dob: '',
    phoneNumber: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    companyid: '',
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleClearForm = () => {
    setIsDisabled(!isDisabled);
    setFormData({
      emailAddress: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      middleName: '',
      lastName: '',
      dob: '',
      phoneNumber: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      companyid: '',
    });
    setFormErrors('');
  };

  function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  }

  const validateForm = () => {
    const errors = {};

    if (!formData.emailAddress.trim()) {
      errors.emailAddress = 'Email Address is required';
    } else if (!isValidEmail(formData.emailAddress)) {
      errors.emailAddress = 'Invalid email format';
    }

    if (!formData.password.trim()) {
      errors.password = 'Password is required';
    }

    if (!formData.confirmPassword.trim()) {
      errors.confirmPassword = 'Please confirm your password';
    } else if (formData.password.trim() !== formData.confirmPassword.trim()) {
      errors.confirmPassword = 'Passwords do not match';
    }

    if (!formData.firstName.trim()) {
      errors.firstName = 'First Name is required';
    }

    if (!formData.lastName.trim()) {
      errors.lastName = 'Last Name is required';
    }

    if (!formData.dob.trim()) {
      errors.dob = 'Date of Birth is required';
    }

    if (formData.phoneNumber.trim().length < 12) {
      errors.phoneNumber = 'Phone Number is required';
    }

    if (!formData.street.trim()) {
      errors.street = 'Street is required';
    }

    if (!formData.city.trim()) {
      errors.city = 'City is required';
    }

    if (formData.state === '0') {
      errors.state = 'Please select a State';
    }

    if (!formData.zip.trim()) {
      errors.zip = 'Zip is required';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // New sendEmail function that uses ONLY the new Mailchimp API
  const sendEmail = async () => {
    const emailAddress = formData.emailAddress;
    const sendmail = `<table border="0" cellpadding="0" cellspacing="0" width="100%">
            <tr>
            <td align="center">
                <table border="0" cellpadding="0" cellspacing="0" width="600">
                    <tr>
                        <td align="left" valign="middle" width="25%">
                            <img src="https://starbehaviors.com/uploadimage/star_behaviors_llc_cover.jpeg" width="136" height="48" alt="Star Behaviors Logo">
                        </td>
                        <td align="right" width="75%">&nbsp;</td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>

<table border="0" cellpadding="0" cellspacing="0" width="100%" bgcolor="#ffffff">
    <tr>
        <td align="center" style="padding: 20px 0;">
            <table border="0" cellpadding="0" cellspacing="0" width="600">
                <tr>
                    <td>
                        <p>Dear ${formData.firstName},</p>
                        <p>Welcome to the Star Behaviors Behavioral Assessment Platform! We're thrilled to have you on board and excited for the journey ahead.</p>
                        <p>Thank you for taking the first step in unlocking your full potential by registering with us. Your decision to join our platform demonstrates your commitment to personal and professional growth, and we're here to support you every step of the way.</p>
                        <p>Now that you're officially a part of our community, it's time to dive into the Star Behaviors Behavioral Assessment. This assessment is not just another test; it's a powerful tool designed to help you distinguish yourself from the crowd. By completing the assessment, you'll gain valuable insights into your Qualities, Core Values, and Behaviors – the very traits that set top performers apart from the rest.</p>
                        <p>Moreover, this assessment will provide you with additional insights into important aspects such as Job Satisfaction. Understanding your strengths and preferences in the context of job satisfaction can be instrumental in shaping your career path and ensuring fulfillment in your professional endeavors.</p>
                        <p>Whether you're aiming to excel in your career, stand out in the job market, or simply gain a deeper understanding of yourself, the Star Behaviors Behavioral Assessment is your key to success. So, don't hesitate – let's embark on this transformative journey together.</p>
                        <p>To get started, simply log in to your account and access the assessment section. If you have any questions or need assistance along the way, our dedicated support team is here to help.</p>
                        <p>Thank you once again for choosing Star Behaviors. We can't wait to see you shine!</p>
                        <p>Warm regards,</p>
                        <p>Star Behaviors Assessment Support Team</p>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>`;

    const newRequestBody = {
      email: emailAddress,
      name: formData.firstName + " " + formData.lastName,
      subject: "Welcome to Star Behaviors Behavioral Assessment Platform!",
      message: sendmail,
    };

    try {
      await fetch("https://bakemywords.com/mailchimp/sendmail.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        mode: "no-cors",
        body: JSON.stringify(newRequestBody),
      });
      console.log("New email API call sent.");
    } catch (err) {
      console.error("New email API error:", err);
    }
  };

  const handleRegister = () => {
    const isValid = validateForm();
    console.log(isValid);
    const errors = {};

    if (isValid) {
      const {
        emailAddress,
        password,
        confirmPassword,
        firstName,
        lastName,
        middleName,
        dob,
        phoneNumber,
        street,
        city,
        state,
        zip
      } = formData;

      const data = {
        DriverEmail: emailAddress,
        LoginPassword: password,
        FirstName: firstName,
        LastName: lastName,
        Middle_Name: middleName,
        dob: dob,
        PhoneNumber: phoneNumber,
        Street: street,
        CurrentCity: city,
        CurrentState: state,
        ZipCode: zip,
        companyid: lastSegment,
      };

      axios
        .post(API_BASE_URL + '/sbdriverregistrations', data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if (response.data.Message === "Driver registered successfully") {
            localStorage.setItem("token1", true);
            localStorage.setItem("user1_data", JSON.stringify(response.data));
            localStorage.setItem("useremail", emailAddress);
            localStorage.setItem("assessment_id", response.data.assessementid);
            localStorage.setItem("userid", response.data.DriverId);
            localStorage.setItem("DriverId", response.data.DriverId);

            const firstName = formData.firstName;
            const lastName = formData.lastName;
            const street1 = formData.street;
            const street = street1 != null && street1 !== undefined ? street1 : "";
            const currentCity = formData.city;
            const currentState = formData.state;
            const zipcodedata = formData.zip;
            const fullName = firstName + ' ' + lastName;
            const fullAddress = street + ' ' + currentCity + ' ' + currentState;
            localStorage.setItem("driveraddress", fullAddress);
            localStorage.setItem("drivername", fullName);
            localStorage.setItem("driverphone", formData.phoneNumber);
            localStorage.setItem("driverZip", zipcodedata);

            sendEmail();

            setTimeout(function () {
              window.location = "/landingpage/Assessment";
            }, 2000);
          } else {
            console.log(response.data.Message);
            if (response.data.Message === "Driver with this email already registered") {
              errors.emailAddress = "Email address already exists";
              setFormErrors(errors);
            }
          }
        })
        .catch((error) => {
          console.error('Registration failed:', error);
        });
    } else {
      console.error('Form validation failed');
    }
  };

  return (
    <div className={styles.main_container}>

      {/* Modal for incorrect user (same style as in LandingPage) */}
      {isIncorrectUser && (
        <div className="incorrect-user-modal">
          <div className="incorrect-user-modal-content">
            <h2>Page restricted</h2>
            <p>
              Please check your url or go to{' '}
              <a href="https://sb.starbehaviors.com/landingpage/">
                https://sb.starbehaviors.com/landingpage/
              </a>
            </p>
          </div>
        </div>
      )}

      <div className="container">
        <div className="position-relative d-flex flex-column justify-content-center align-items-center py-3 px-2">
          <div className="register-page">
            <div className="card">
              <h4 className="card-header text-uppercase">Register</h4>
              <div className="card-body">
                <p>* - Field is required.</p>
                <p>Login Details</p>
                <form>
                  <div className="bod">
                    <div className="form-floating mb-3">
                      <input
                        id="emailAddress"
                        className={`form-control ${formErrors.emailAddress ? 'is-invalid' : ''}`}
                        type="email"
                        placeholder="Email Address"
                        value={formData.emailAddress}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="emailAddress">Email Address*</label>
                      {formErrors.emailAddress && (
                        <div className="invalid-feedback">{formErrors.emailAddress}</div>
                      )}
                      <small className="text-muted px-1">jdoe@email.com</small>
                    </div>
                    <div className="input-group mb-3">
                      <div className="form-floating flex-fill">
                        <input
                          id="password"
                          className={`form-control ${formErrors.password ? 'is-invalid' : ''}`}
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          value={formData.password}
                          onChange={handleInputChange}
                        />
                        <label htmlFor="password">Password*</label>
                        {formErrors.password && (
                          <div className="invalid-feedback">{formErrors.password}</div>
                        )}
                      </div>
                      <button
                        className="btn btn-outline-secondary"
                        style={{ marginLeft: '-40px', border: 'none' }}
                        type="button"
                        onClick={handleTogglePasswordVisibility}
                      >
                        <Icon icon={showPassword ? "oi:eye" : "oi:eye"} />
                      </button>
                    </div>
                    <div className="mb-3">
                      <div className="input-group">
                        <div className="form-floating flex-fill">
                          <input
                            id="confirmPassword"
                            className={`form-control ${formErrors.confirmPassword ? 'is-invalid' : ''}`}
                            type={showPassword ? "text" : "password"}
                            placeholder="Confirm Password"
                            value={formData.confirmPassword}
                            onChange={handleInputChange}
                          />
                          <label htmlFor="confirmPassword">Confirm Password*</label>
                          {formErrors.confirmPassword && (
                            <div className="invalid-feedback">{formErrors.confirmPassword}</div>
                          )}
                        </div>
                        <button
                          className="btn btn-outline-secondary"
                          style={{ marginLeft: '-40px', border: 'none' }}
                          type="button"
                          onClick={handleTogglePasswordVisibility}
                        >
                          <Icon icon={showPassword ? "oi:eye" : "oi:eye"} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <p>Driver Details</p>
                  <div className="bod">
                    <div className="form-floating mb-3">
                      <input
                        id="firstName"
                        className={`form-control ${formErrors.firstName ? 'is-invalid' : ''}`}
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="firstName">First Name*</label>
                      {formErrors.firstName && (
                        <div className="invalid-feedback">{formErrors.firstName}</div>
                      )}
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        id="middleName"
                        className="form-control"
                        placeholder="Middle Name"
                        value={formData.middleName}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="middleName">Middle Name</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        id="lastName"
                        className={`form-control ${formErrors.lastName ? 'is-invalid' : ''}`}
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="lastName">Last Name*</label>
                      {formErrors.lastName && (
                        <div className="invalid-feedback">{formErrors.lastName}</div>
                      )}
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="date"
                        id="dob"
                        className={`form-control ${formErrors.dob ? 'is-invalid' : ''}`}
                        placeholder="DOB"
                        value={formData.dob}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="dob">Date of birth</label>
                      {formErrors.dob && (
                        <div className="invalid-feedback">{formErrors.dob}</div>
                      )}
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        id="phoneNumber"
                        placeholder="Phone Number"
                        data-mask="000-000-0000"
                        className={`form-control ${formErrors.phoneNumber ? 'is-invalid' : ''}`}
                        value={formData.phoneNumber}
                        pattern="[0-9]*"
                        inputMode="numeric"
                        onChange={(e) => {
                          const sanitizedValue = e.target.value.replace(/\D/g, '').slice(0, 10);
                          const formattedValue = sanitizedValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
                          handleInputChange({ target: { id: 'phoneNumber', value: formattedValue } });
                        }}
                      />
                      <label htmlFor="phoneNumber">Phone Number*</label>
                      {formErrors.phoneNumber && (
                        <div className="invalid-feedback">{formErrors.phoneNumber}</div>
                      )}
                      <small className="text-muted px-1">111-222-3333</small>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="hidden"
                        readOnly
                        id="street"
                        className="form-control"
                        placeholder="Company Id"
                        value={lastSegment}
                      />
                      <label htmlFor="street">Company Id*</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        id="street"
                        className={`form-control ${formErrors.street ? 'is-invalid' : ''}`}
                        placeholder="Street"
                        value={formData.street}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="street">Street*</label>
                      {formErrors.street && (
                        <div className="invalid-feedback">{formErrors.street}</div>
                      )}
                    </div>
                    <div className="form-floating mb-3">
                      <select
                        id="country"
                        className={`form-select mb-3 ${formErrors.country ? 'is-invalid' : ''}`}
                        value={formData.country}
                        onChange={handleChange}
                      >
                        <option value="">Select Country</option>
                        <option value="US">United States</option>
                        <option value="CA">Canada</option>
                      </select>
                      <label htmlFor="country">Country*</label>
                      {formErrors.state && (
                        <div className="invalid-feedback">{formErrors.country}</div>
                      )}
                    </div>
                    <div className="form-floating mb-3">
                      <select
                        id="state"
                        className={`form-select mb-3 ${formErrors.state ? 'is-invalid' : ''}`}
                        value={formData.state}
                        onChange={handleStateChange}
                      >
                        <option value="">Select State/Province</option>
                        {stateValue.result?.map((item) => {
                          return (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                      <label htmlFor="state">State/Province*</label>
                      {formErrors.state && (
                        <div className="invalid-feedback">{formErrors.state}</div>
                      )}
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        id="city"
                        className="form-control"
                        value={formData.city}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="city">City*</label>
                      {formErrors.city && (
                        <div className="invalid-feedback">{formErrors.city}</div>
                      )}
                    </div>
                    {countryValue === 'US' ? (
                      <div className="form-floating mb-3">
                        <input
                          id="zip"
                          className={`form-control ${formErrors.zip ? 'is-invalid' : ''}`}
                          type="number"
                          pattern="\d*"
                          placeholder="Zip"
                          value={formData.zip}
                          onChange={handleInputChange}
                        />
                        <label htmlFor="zip">Zip*</label>
                        {formErrors.zip && (
                          <div className="invalid-feedback">{formErrors.zip}</div>
                        )}
                      </div>
                    ) : (
                      <div className="form-floating mb-3">
                        <input
                          id="zip"
                          className={`form-control ${formErrors.zip ? 'is-invalid' : ''}`}
                          type="text"
                          placeholder="Postal Code"
                          value={formData.zip}
                          onChange={handleInputChange}
                        />
                        <label htmlFor="zip">Postal Code*</label>
                        {formErrors.zip && (
                          <div className="invalid-feedback">{formErrors.zip}</div>
                        )}
                      </div>
                    )}
                  </div>
                  <br />
                  <div>
                    <button
                      className="btn btn-primary"
                      type="button"
                      id="save"
                      disabled={isDisabled}
                      onClick={handleRegister}
                    >
                      Register
                    </button>
                    <button
                      type="button"
                      onClick={handleClearForm}
                      className="btn btn-secondary"
                      style={{ marginLeft: '10px' }}
                    >
                      Clear Form
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <ul className="nav nav-pills nav-justified mt-3">
              <li className="nav-item">
                <a
                  className="nav-link text-decoration-underline small p-2"
                  href="https://sb.starbehaviors.com/imgs/StarBehaviorsPrivacyPolicy.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-decoration-underline small p-2"
                  href="https://sb.starbehaviors.com/imgs/StarBehaviorsUserAgreement.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  User Agreement
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegistrationForm;
