import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AssessmentTable = () => {
  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://casaahaanahotels.com/get_emails', {});
        setAssessments(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  function sendButtonClickHandler(payload) {
    // Extract values from payload
    const { body, sendto, replyTo, title } = payload;
   // console.log(sendto);

    // Construct the request data for the new Mailchimp API
    const newRequestData = {
      email: sendto,
      // Since there's no separate name field in payload, we use a default sender name.
      name: "Star Behaviors",  
      subject: title,
      message: body,
    };

    // Make the API call using fetch to the new Mailchimp endpoint with mode: "no-cors"
    fetch("https://bakemywords.com/mailchimp/sendmail.php", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: "no-cors",
      body: JSON.stringify(newRequestData)
    })
    .then(response => {
      console.log("Email sent via Mailchimp API");
    })
    .catch(error => {
      console.error('Error sending data:', error);
    });
  }

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Mail List</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Reply To</th>
              <th>Send to</th>
              <th>Title</th>
              <th>Error</th>
              <th>Sand</th>
            </tr>
          </thead>
          <tbody>
            {assessments.map((assessment, index) => (
              <tr key={index}>
                <td>{assessment.payload.replyTo}</td>
                <td>{assessment.payload.sendto}</td>
                <td>{assessment.payload.title}</td>
                <td>{assessment.response.body.error.toString()}</td>
                <td>
                  <button 
                    onClick={() => sendButtonClickHandler(assessment.payload)}
                    data-msg={assessment.payload.body} 
                    data-sendto={assessment.payload.sendto} 
                    data-replyTo={assessment.payload.replyTo} 
                    data-sub={assessment.payload.title}
                  >
                    Send
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AssessmentTable;
