import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from '@iconify/react';
import { API_BASE_URL } from '../../../config/serverApiConfig';
import { Modal, Button } from 'react-bootstrap';
import * as apiService from '../../../services';
import "../styles.module.css"; // <-- Import the new custom CSS file with the .sb-login-* classes

const Login = () => {
  const [data, setData] = useState({ user: "", password: "" });
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [email, setNewemail] = useState('');
  const [changePasswordmsg, setchangePasswordmsg] = useState("");
  const navigate = useNavigate();

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      const url = API_BASE_URL + "/sbdriverlogin";
      const { data: res } = await axios.post(url, data);

      if (res.user_data && res.user_login) {
        // Store user data in localStorage
        const user = res.user_data[0];
        const fullName = `${user.FirstName} ${user.LastName}`;
        const street = user.Street || "";
        const fullAddress = `${street} ${user.CurrentCity} ${user.CurrentState}`;

        localStorage.setItem("token1", res.user_login);
        localStorage.setItem('user1_data', JSON.stringify(user));
        localStorage.setItem("registrationNo", user.companyid);
        localStorage.setItem("userid", user.DriverId);
        localStorage.setItem("useremail", user.DriverEmail);
        localStorage.setItem("driveraddress", fullAddress);
        localStorage.setItem("drivername", fullName);
        localStorage.setItem("driverphone", user.PhoneNumber);
        localStorage.setItem("driverZip", user.ZipCode);

        // Fetch user assessments
        const requestOptions = {
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ "userid": user.DriverId }),
          redirect: 'follow'
        };

        const response = await fetch("https://casaahaanahotels.com/get_user_assessments", requestOptions);
        const result = await response.json();

        if (result.Assessments) {
          localStorage.setItem('assessment_id', result.Assessments);
        } else if (result.assessments) {
          localStorage.setItem('assessment_id', result.assessments);
        }

        // Redirect to "/landingpage/Assessment"
        window.location = "/landingpage/Assessment";
      } else {
        setError(res.Message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.Message);
      } else {
        setError("An unexpected error occurred.");
      }
    }
  };

  const handleRegister = () => {
    const reg = localStorage.getItem("reg");
    navigate('/user-register/' + reg);
  };

  useEffect(() => {
    // Dynamically append the partner branding image if not already present
    const imageFile = localStorage.getItem("patnerImg");
    const existingImg = document.getElementById('bandingImg');
    if (!existingImg) {
      const img = document.createElement('img');
      const reg = localStorage.getItem("reg");
      img.src = 'https://starbehaviors.com/uploadimage/' + imageFile;
      img.width = 136;
      img.height = 48;
      img.alt = 'Star Behaviors Logo';
      img.id = 'bandingImg';

      // Create an anchor element so that clicking the image goes to the partner’s landing page
      const anchor = document.createElement('a');
      anchor.href = 'https://sb.starbehaviors.com/user-landingpage/' + reg;
      anchor.appendChild(img);
      const div = document.getElementById('banding');
      if (div) {
        div.appendChild(anchor);
      }
    }

    // Remove an existing image from a certain div
    const div1 = document.getElementById('st');
    const imgElement = document.getElementById('imgId');
    if (div1 && imgElement) {
      div1.removeChild(imgElement);
    }

    // Replace or re-render the register button
    const divRegister = document.getElementById('register');
    const button = document.getElementById('reg');
    if (divRegister && button) {
      divRegister.removeChild(button);

      const newButton = document.createElement('button');
      newButton.className = 'nav-link btn btn-link text-white';
      newButton.textContent = 'Register';
      newButton.id = 'reg';
      newButton.addEventListener('click', handleRegister);

      divRegister.appendChild(newButton);
    }
  }, []);

  const handleCloseChangePassword = () => {
    setShowChangePassword(false);
  };

  const handleChangePassword = () => {
    setShowChangePassword(true);
  };

  const handlePasswordChange = async () => {
    try {
      const response = await apiService.forgetdriverPassword(email);
      setchangePasswordmsg(response.Message);

      const sendmail = `<body style="font-family:Arial,sans-serif;background-color:#f4f4f4;margin:0;padding:0">
        <div class="container" style="width:70%;margin:30px auto;background:#fff;padding:20px;box-shadow:0 0 15px rgba(0,0,0,.2);border-radius:8px;text-align:center">
          <div class="header" style="margin-bottom:20px">
            <img src="https://starbehaviors.com/uploadimage/star_behaviors_llc_cover.jpeg" alt="Company Logo" style="width:auto;height:100px;">
          </div>
          <div class="content" style="text-align:left;line-height:1.6">
            <h4 style="margin-top:0">Your Password Reset Information</h4>
            <p style="font-size:16px;">Dear ${response.name},</p>
            <p style="font-size:16px;">Your password for Star Behaviors has been reset as requested today. Below are your new login details:
              <b><br>Username:</b> ${response.email},<br> <b>Temp Password:</b> ${response.tempPassword}
            </p>
            <p style="font-size:16px;">Please log in to your Star Behaviors account using the following link:</p>
            <p style="font-size:16px;"><a href="https://sbnew.starbehaviors.com/user-landingpage/changepassword" style="color:#06c;text-decoration:none">Star Behaviors Login</a></p>
            <p style="font-size:16px;">If you did not expect your password to be reset, please contact Star Behaviors immediately at <a href="mailto:support@starbehaviour.com" style="color:#06c;text-decoration:none">support@starbehaviour.com</a>.</p>
            <p style="font-size:16px;">Thank you for choosing Star Behaviors!<br>Best regards,</p>
          </div>
          <div class="footer" style="margin-top:20px;font-size:.9em;color:#555">
            Star Behaviors Support
          </div>
        </div>
      </body>`;

      // New Mailchimp API call using the new endpoint
      const payload = {
        email: email,
        name: response.name,
        subject: "Forget Password",
        message: sendmail
      };

      await fetch("https://bakemywords.com/mailchimp/sendmail.php", {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
      });
      console.log("Password reset email sent successfully.");
    } catch (error) {
      console.error('Error changing password:', error);
    }
  };

  return (
    <>
      {/* Forgot Password Modal */}
      {showChangePassword && (
        <Modal
          show={showChangePassword}
          onHide={handleCloseChangePassword}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Forgot Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>Email</label>
            <br />
            <input
              className="form-control"
              placeholder="Enter Your Email"
              type="email"
              value={email}
              onChange={(e) => setNewemail(e.target.value)}
            />
            <span style={{ color: "red" }}>{changePasswordmsg}</span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseChangePassword}>
              Close
            </Button>
            <Button variant="primary" onClick={handlePasswordChange}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Outer container using styles from the second page */}
      <div className="container">
        <div className="position-relative d-flex flex-column justify-content-center align-items-center py-3 px-2">
          <div className="card sb-login-card admin-login-page">
            <h4 className="card-header text-uppercase">User Login</h4>
            <div className="card-body sb-login-card-body">
              <form onSubmit={handleSubmit} className="sb-login-form">
                <div className="form-floating mb-3">
                  <input
                    id="emailAddress"
                    className="form-control sb-login-input"
                    type="email"
                    name="user"
                    onChange={handleChange}
                    value={data.user}
                    required
                    placeholder="Email Address"
                  />
                  <label htmlFor="emailAddress">Email Address</label>
                </div>
                <div className="input-group mb-3">
                  <div className="form-floating flex-fill">
                    <input
                      id="password"
                      className="form-control sb-login-input"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onChange={handleChange}
                      value={data.password}
                      required
                      placeholder="Password"
                    />
                    <label htmlFor="password">Password</label>
                  </div>
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={handleTogglePasswordVisibility}
                  >
                    <Icon icon={showPassword ? "oi:eye" : "oi:eye"} />
                  </button>
                </div>

                {error && <div className="sb-login-error">{error}</div>}

                <div className="sb-login-btn-group">
                  <button type="submit" className="btn btn-primary">
                    Login
                  </button>
                  <button
                    className="btn btn-primary btn-link text-white mx-2"
                    type="button"
                    onClick={handleChangePassword}
                  >
                    Forgot Password
                  </button>
                </div>
              </form>
              <ul className="nav nav-pills nav-justified mt-3">
                <li className="nav-item">
                  <a
                    className="nav-link text-decoration-underline small p-2"
                    href="https://sb.starbehaviors.com/imgs/StarBehaviorsPrivacyPolicy.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-decoration-underline small p-2"
                    href="https://sb.starbehaviors.com/imgs/StarBehaviorsUserAgreement.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    User Agreement
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
