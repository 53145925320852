import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Function to fetch company data
const fetchCompanyData = async () => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({ "useremail": "" });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  try {
    const response = await fetch("https://casaahaanahotels.com/search_company_data", requestOptions);
    return await response.json();
  } catch (error) {
    console.error("Error fetching company data:", error);
    return [];
  }
};

const AssessmentTable = () => {
  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(true);

  // For per-assessment sending states
  const [loadingUserEmailId, setLoadingUserEmailId] = useState(null);
  const [loadingAdminEmailId, setLoadingAdminEmailId] = useState(null);

  // For company filter
  const [companies, setCompanies] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState("");
  const [selectedCompanyName, setSelectedCompanyName] = useState("");

  // Fetch assessment data
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const response = await axios.get('https://casaahaanahotels.com/list_assessments');
        if (isMounted) {
          setAssessments(response.data.result);
          setLoading(false);
        }
      } catch (error) {
        if (isMounted) setLoading(false);
        console.error("Error fetching assessments:", error);
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  // Fetch company data
  useEffect(() => {
    let isMounted = true;
    const loadCompanies = async () => {
      const data = await fetchCompanyData();
      if (isMounted) {
        setCompanies(data);
      }
    };

    loadCompanies();
    return () => {
      isMounted = false;
    };
  }, []);

  // Handle the <select> for company selection and set the emails as well
  const handleChange = (event) => {
    let emailString = "";
    const selectedCompany = event.target.value;
    setSelectedCompanyName(selectedCompany);

    // Find the matching company to extract the assessmentRecipient
    const company = companies.find((c) => c.companyname === selectedCompany);
    if (company) {
      if (Array.isArray(company.assessmentRecipient)) {
        emailString = company.assessmentRecipient.join(",");
      } else if (typeof company.assessmentRecipient === "string") {
        emailString = company.assessmentRecipient;
      } else {
        emailString = "";
      }
    }
    setSelectedEmails(emailString);
  };

  // Helper: get today's date as mm/dd/yyyy
  const getCurrentDate = () => {
    const d = new Date();
    return `${(d.getMonth() + 1).toString().padStart(2, "0")}/${d
      .getDate()
      .toString()
      .padStart(2, "0")}/${d.getFullYear()}`;
  };

  // Build user email body
  const buildUserBody = (payload) => {
    if (parseInt(payload.stars, 10) >= 3) {
      // For stars 3,4,5
      return `
<body style="font-family:Arial,sans-serif;background-color:#f4f4f4;margin:0;padding:0">
  <div class="container" style="width:96%;margin:30px auto;background:#fff;padding:20px;box-shadow:0 0 15px rgba(0,0,0,0.2);border-radius:8px">
    <div class="header" style="text-align:center">
      <img src="https://starbehaviors.com/uploadimage/star_behaviors_llc_cover.jpeg" alt="Star Behaviors Logo" style="width:auto;max-width:auto;height:100px;display:block;margin:0 auto">
    </div>
    <div class="content" style="margin-top:20px;line-height:1.6">
      <h2 style="text-align:center">Assessment Completed - Congratulations on Your Achievement!</h2>
      <p style="font-size: 16px;">Dear ${payload.drivername},</p>
      <p style="font-size: 16px;">We're thrilled to inform you that you've successfully completed [Part 1] of the Star Behaviors Assessment. Your journey towards a fulfilling career has taken a significant leap forward!</p>
      <center>
         <h3>Your Star Score:</h3>
         <img src="https://starbehaviors.com/uploadimage/${payload.stars}.png">
      </center>
      <p style="font-size: 16px;">Your <b>${payload.stars}-star rating</b> signifies your good alignment with the required behavioral traits for this position, indicating a high likelihood of long-term satisfaction and fulfillment in this career. Individuals receiving a ${payload.stars}-star rating are normally well-suited for both formal (e.g., post-secondary training) and informal (on-the-job) training programs.</p>
      <p style="font-size: 16px;">Whether you're entering the job market for the first time, considering a career change, or already established in this field, you can confidently pursue this profession. Prospective employers are likely to be eager to explore training and employment opportunities with candidates of your caliber.</p>
      <h3>What This Means for Your Career</h3>
      <p style="font-size: 16px;">Receiving a ${payload.stars}-star rating places you in an enviable position. It signifies that you're exceptionally well-suited for a range of professional development opportunities, encompassing both formal education (such as post-secondary training programs) and practical, on-the-job experiences.</p>
      <p style="font-size: 16px;">Whether you're stepping into the job market for the first time, contemplating a career shift, or furthering your journey in your current field, this score empowers you to confidently advance. Employers are always on the lookout for candidates of your caliber and potential, making you a desirable prospect for training and employment opportunities.</p>
      <p style="font-size: 16px;">At Star Behaviors, LLC, we take pride in guiding individuals towards careers that not only match their skills and interests but also ignite their passion. Thank you for trusting us on this pivotal journey. Remember, your success isn't just written in the stars—it's also shaped by your dedication and our support.</p>
      <p style="font-size: 16px;">Wishing you a stellar career ahead!</p>
      <p>Warm regards,</p>
      <p style="font-size: 16px;">TJ Graff<br>CEO<br>Star Behaviors, LLC</p>
    </div>
    <div class="footer" style="text-align:center;margin-top:20px;font-style:italic"><b>Powered by Star Behaviors.</b></div>
  </div>
</body>
      `;
    } else {
      // For stars < 3
      return `
<body style="font-family:Arial,sans-serif;background-color:#f4f4f4;margin:0;padding:0">
  <div class="container" style="width:96%;margin:30px auto;background:#fff;padding:20px;box-shadow:0 0 15px rgba(0,0,0,0.2);border-radius:8px">
    <div class="header" style="text-align:center">
      <img src="https://starbehaviors.com/uploadimage/star_behaviors_llc_cover.jpeg" alt="Star Behaviors Logo" style="width:auto;max-width:auto;height:100px;display:block;margin:0 auto">
    </div>
    <div class="content" style="margin-top:20px;line-height:1.6">
      <h2 style="text-align:center">Assessment Completed</h2>
      <p style="font-size: 16px;">Dear ${payload.drivername},</p>
      <p style="font-size: 16px;">We're thrilled to inform you that you've successfully completed [Part 1] of the Star Behaviors Assessment. Your journey towards a fulfilling career has taken a significant leap forward!</p>
      <center>
         <h3>Your Star Score:</h3>
         <img src="https://starbehaviors.com/uploadimage/${payload.stars}.png">
      </center>
      <p style="font-size: 16px;">The Star Behaviors Behavioral Assessment you've just completed is built upon an extensive job analysis involving individuals who have achieved lasting success and contentment in this career. Our research has identified numerous behaviors critical for your sustained happiness in this field. Research findings indicate that individuals scoring ${payload.stars} stars or lower are unlikely to experience long-term fulfillment or happiness in this profession.</p>
      <p style="font-size: 16px;">Our partners are primarily interested in exploring training and employment opportunities with candidates who score 3, 4, or 5 stars. To ensure your enduring job satisfaction, we encourage you to consider completing one of our various other compatibility self-assessments to find the career that is a perfect fit for you!</p>
      <p>Warm regards,</p>
      <p style="font-size: 16px;">TJ Graff<br>CEO<br>Star Behaviors, LLC</p>
    </div>
    <div class="footer" style="text-align:center;margin-top:20px;font-style:italic"><b>Powered by Star Behaviors.</b></div>
  </div>
</body>
      `;
    }
  };

  // Build admin email body
  const buildAdminBody = (payload) => {
    return `
<body style="font-family:Arial,sans-serif;background-color:#f4f4f4;margin:0;padding:0">
  <div class="container" style="width:96%;margin:30px auto;background:#fff;padding:20px;box-shadow:0 0 15px rgba(0,0,0,0.2);border-radius:8px">
    <div class="header" style="text-align:center;margin-bottom:10px">
      <img src="https://starbehaviors.com/uploadimage/star_behaviors_llc_cover.jpeg" alt="Star Behaviors Logo" style="width:auto;height:100px">
    </div>
    <h2 style="text-align:center;margin-top:0">Star Behaviors Assessment Results - ADMIN</h2>
    <p style="font-size:16px;">
      Hello Team,<br/>
      Below are the assessment details of a recent candidate. 
      Please reach out to them as soon as possible.
    </p>
    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Date:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.emailDate}</span>
    </div>
    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">To:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.assessmentRecipient}</span>
    </div>
    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Name:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.drivername}</span>
    </div>
    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Address:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.driveraddress}</span>
    </div>
    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Phone:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.driverphone}</span>
    </div>
    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Email:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.useremail}</span>
    </div>
    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Assessment Completed:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.assessment_date}</span>
    </div>
    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:30%;margin-right:5px;font-size: 16px;">Score:</label>
      <span style="width:70%;text-align:left;font-size: 16px;">
        <img src="https://starbehaviors.com/uploadimage/${payload.stars}.png" />
      </span>
    </div>
    <div class="powered" style="text-align:center;margin-top:40px;font-style:italic">Powered by Star Behaviors.</div>
  </div>
</body>
    `;
  };

  // Actually handle the email sending
  const sendEmail = async (assessment, isAdmin) => {
    const { score, timestamp, driver_details } = assessment;
    const formattedDate = new Date(timestamp).toLocaleDateString("en-US");

    // Common fields
    const payload = {
      // We'll still store this for reference in the email body
      assessmentRecipient: assessment.company_details?.assessmentRecipient || [],
      drivername: `${driver_details.FirstName} ${driver_details.LastName}`,
      driveraddress: `${driver_details.Street || ""} ${driver_details.CurrentCity || ""} ${driver_details.CurrentState || ""}`.trim() || "Not Provided",
      driverphone: driver_details.PhoneNumber || "Not Provided",
      useremail: driver_details.DriverEmail,
      assessment_date: formattedDate,
      stars: score,
      emailDate: getCurrentDate(), // For "Date:" field in the email
    };

    // The new mail API endpoint
    const mailApiUrl = "https://bakemywords.com/mailchimp/sendmail.php";

    if (isAdmin) {
      // Instead of using a single adminEmail, we now loop through the entire assessmentRecipient array
      const adminRecipients = assessment.company_details?.assessmentRecipient;
      if (!Array.isArray(adminRecipients) || adminRecipients.length === 0) {
        alert("No admin recipients found for this company.");
        return;
      }

      setLoadingAdminEmailId(assessment.assessementid);

      const emailBody = buildAdminBody(payload);

      // Send an email to each recipient in the array
      try {
        // We can do this sequentially in a loop or in parallel with Promise.all.
        // For simplicity, let's do it sequentially in a for-of loop.
        for (const adminEmail of adminRecipients) {
          const requestBody = {
            email: adminEmail,
            name: "Company Admin",
            subject: "Star Behaviors Assessment Results - ADMIN",
            message: emailBody
          };

          console.log("Sending admin email to:", adminEmail);
          await fetch(mailApiUrl, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            mode: "no-cors",
            body: JSON.stringify(requestBody)
          });
        }
        alert("Successfully sent Company Admin Mail to all recipients");
      } catch (err) {
        console.error(err);
        alert("Error sending Company Admin Mail");
      } finally {
        setLoadingAdminEmailId(null);
      }
    } else {
      // Send to user
      const userEmail = driver_details.DriverEmail;
      if (!userEmail) {
        alert("No user email found for this candidate.");
        return;
      }

      setLoadingUserEmailId(assessment.assessementid);

      const emailBody = buildUserBody(payload);

      const requestBody = {
        email: userEmail,
        name: payload.drivername,
        subject: "Star Behaviors Assessment Results - USER",
        message: emailBody
      };

      console.log("User Request Body:", requestBody);

      try {
        await fetch(mailApiUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          mode: "no-cors",
          body: JSON.stringify(requestBody)
        });
        alert("Successfully sent User Email");
      } catch (err) {
        console.error(err);
        alert("Error sending User Email");
      } finally {
        setLoadingUserEmailId(null);
      }
    }
  };

  // Filter assessments based on the selected company name.
  const filteredAssessments = selectedCompanyName
    ? assessments.filter(
        (assessment) =>
          assessment.company_details?.companyname === selectedCompanyName
      )
    : assessments;

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Assessment List</h2>

      {/* Company Selection */}
      <div>
        <select onChange={handleChange}>
          <option value="">Select a company</option>
          {companies.map((company) => (
            <option key={company.id} value={company.companyname}>
              {company.companyname}
            </option>
          ))}
        </select>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th>Company Name</th>
              <th>Star Score</th>
              <th>Assessment Completed</th>
              <th>Validity</th>
              <th>Send Driver Mail</th>
              <th>Send Company Admin Mail</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {filteredAssessments?.map((assessment, index) => {
              if (assessment?.driverid && assessment?.score) {
                const formattedDate = new Date(assessment.timestamp).toLocaleDateString("en-US");
                const validityDate = new Date(assessment.validity).toLocaleDateString("en-US");
                const companyName = assessment?.company_details?.companyname || "N/A";
                const driverName = `${assessment?.driver_details?.FirstName || "N/A"} ${assessment?.driver_details?.LastName || "N/A"}`;
                const driverEmail = assessment?.driver_details?.DriverEmail || "N/A";

                return (
                  <tr key={`${assessment.assessementid}-${index}`}>
                    <td>{driverName}</td>
                    <td>{driverEmail}</td>
                    <td>{companyName}</td>
                    <td>
                      <img
                        src={`https://starbehaviors.com/uploadimage/${assessment.score}.png`}
                        alt={`Score: ${assessment.score}`}
                      />
                    </td>
                    <td>{formattedDate}</td>
                    <td>{validityDate}</td>
                    <td>
                      <button
                        onClick={() => sendEmail(assessment, false)}
                        disabled={loadingUserEmailId === assessment.assessementid}
                      >
                        {loadingUserEmailId === assessment.assessementid ? "Sending..." : "Send User Mail"}
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => sendEmail(assessment, true)}
                        disabled={loadingAdminEmailId === assessment.assessementid}
                      >
                        {loadingAdminEmailId === assessment.assessementid ? "Sending..." : "Send Company Admin Mail"}
                      </button>
                    </td>
                    <td>
                      <a
                        href={`https://sb.starbehaviors.com/landingpage/showassessment/${assessment.assessementid}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                );
              }
              return null;
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AssessmentTable;
