import { useState } from "react";
import { Link, useNavigate} from "react-router-dom";
import logo from '../../assets/imgs/star_behaviors_logo.png'
const Header = () => {
    const user = localStorage.getItem("token");
    const navigate = useNavigate();
   


     
     const user1 = localStorage.getItem("token1");
    

    const handleLogout = () => {
        localStorage.removeItem("token");
        navigate('/login');
        // window.location.reload();
        
    };

     const handleRegister = () => {
        const reg = localStorage.getItem("reg");
        
        navigate('/landingpage/register/'+reg);
        // window.location.reload();
    };

     const handleLogout1 = () => {
        localStorage.removeItem("token1");
        navigate('landingpage/login');
        // window.location.reload();
        
    };
  const reg = localStorage.getItem("reg");

    return (
        <>
            <div className="navbar navbar-light bg-primary p-2" id="ref">
             
               <div id="st"><img
                src={logo} id="imgId" width="200" height="48" alt="Star Behaviors Logo" /></div>
                     <a href={`https://sb.starbehaviors.com/landingpage/${reg}`} style={{ marginLeft: "-68%" }}>
                      <div id="banding"></div>
                    </a>
                   
                {user && 
                    <div className="d-flex justify-content-end align-items-center">
                        <button className="nav-link btn btn-link text-white" onClick={handleLogout} id="logout">Logout</button>
                    </div>
                }

                {user1 && user ==null && 

                <div className="d-flex justify-content-end align-items-center" id="log">
                          <button className="nav-link btn btn-link text-white" onClick={handleLogout1} id="logout1">Logout</button>
                 </div>
                }

                {user1 == null && user ==null &&

                <div className="d-flex justify-content-end align-items-center" id="register">
                  <button className="nav-link btn btn-link text-white" onClick={handleRegister} id="reg">Register</button>
                 </div>
                }
            

               

               
             
               
            </div>

        </>
    );
};

export default Header;
