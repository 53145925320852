import './app.css'; // Import your CSS file for styling
import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import styles from '../styles.module.css';
import { API_BASE_URL } from '../../../config/serverApiConfig';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

function AssessmentForm() {
  const navigate = useNavigate();
  const user1 = localStorage.getItem("token1");
  if (user1 == null) {
    navigate('../landingpage/login');
  }

  const [showFirstSection, setShowFirstSection] = useState(true);
  const [showSecondSection, setShowSecondSection] = useState(false);
  const [showThirdSection, setShowThirdSection] = useState(false);
  const [showfourSection, setShowfourSection] = useState(false);
  const [showfiveSection, setShowfiveSection] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [countdown1, setCountdown1] = useState(10);

  const [formData, setFormData] = useState([]);
  const [savedAssessmentData, setSavedAssessmentData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const setsToShow = 5; // Number of sets to show at a time
  const [selectedItems, setSelectedItems] = useState(Array(setsToShow).fill(undefined)); // State to track selected items

  const [validationMessage, setValidationMessage] = useState(''); // Validation message
  const [sbstars, setsbstars] = useState('');
  const [buttonname, setButtonName] = useState('');
  const [page_no, setPageNo] = useState(1);
  const [companayname, setCompanyname] = useState('');

  const [showBackToTop, setShowBackToTop] = useState(false);

  // Scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // You can use 'auto' for instant scrolling
    });
  };

  // Show/hide "back to top" button
  const handleScroll = () => {
    if (window.pageYOffset > 100) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  // Modify get_user_assessments to pass companyassid
  function buttonNameChange() {
    const userid = localStorage.getItem("userid");
    const reg = localStorage.getItem("reg");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: userid,
      companyassid: reg
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://casaahaanahotels.com/get_user_assessments", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.Assessments) {
          setButtonName("Continue Assessment");
          localStorage.setItem('assessment_id', result.Assessments);

          if (page_no >= 16) {
            showass();
          }
        } else if (result.assessments) {
          setButtonName("Continue Assessment");
          localStorage.setItem('assessment_id', result.assessments);

          if (page_no >= 16) {
            showass();
          }
        } else {
          setButtonName("Start Assessment");
        }
      })
      .catch(error => console.log('error', error));
  }

  function xyz() {
    setShowFirstSection(false);
    setShowSecondSection(false);
    setShowThirdSection(true);
  }

  useEffect(() => {
    buttonNameChange();
    const companayname = localStorage.getItem("companyname");
    setCompanyname(companayname);

    const fetchData1 = () => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const userid = localStorage.getItem("userid");
      const assessmentId = localStorage.getItem("assessment_id") || '';

      const raw = JSON.stringify({
        userid: userid,
        assessmentid: assessmentId,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("https://casaahaanahotels.com/show_assessment", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (Array.isArray(result) && result.length > 0) {
            if (result !== null && result.length > 0) {
              const highestPageNo = Math.max(...result.map((data) => data.page_no));
              if (highestPageNo === 0) {
                setPageNo(1);
                setButtonName("Start Assessment");

                const userId = localStorage.getItem("userid");
                const raw1 = JSON.stringify({
                  Userid: userId,
                  Assessementid: assessmentId,
                  pageid: page_no,
                });

                const requestOptions1 = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw1,
                  redirect: "follow",
                };

                fetch("https://casaahaanahotels.com/get_random_assessement", requestOptions1)
                  .then((response) => {
                    if (!response.ok) {
                      throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                  })
                  .then((data) => {
                    if (data.driverbehaviour && data.driverskills) {
                      const mergedData = [];
                      const minLength = Math.min(
                        data.driverbehaviour.length,
                        data.driverskills.length
                      );

                      const assessmentIdNew = data.assessment_id;
                      localStorage.setItem("assessment_id", assessmentIdNew);

                      for (let i = 0; i < minLength; i++) {
                        const skillObj = data.driverskills[i];
                        const behaviorObj = data.driverbehaviour[i];

                        if (skillObj.skills && behaviorObj.behaviour) {
                          mergedData.push({
                            skill: skillObj.skills,
                            skillId: skillObj.id,
                            skillName: skillObj.name,
                            randomInteger: Math.floor(Math.random() * (1 - 2 + 2) + 2),
                            behavior: behaviorObj.behaviour,
                            behaviorId: behaviorObj.id,
                            behaviorName: behaviorObj.name,
                            randomInteger1: Math.floor(Math.random() * (3 - 1 + 1) + 1),
                          });
                        }
                      }
                      setFormData(mergedData);
                    } else {
                      console.error("Missing driverbehaviour or driverskills array in the API response.");
                    }
                  })
                  .catch((error) => {
                    console.error("An error occurred:", error);
                  });
              } else {
                if (highestPageNo < 16) {
                  setPageNo(highestPageNo + 1);
                  setButtonName("Continue Assessment");

                  const userId = localStorage.getItem("userid");
                  const raw1 = JSON.stringify({
                    Userid: userId,
                    Assessementid: assessmentId,
                    pageid: highestPageNo + 1,
                  });

                  const requestOptions1 = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw1,
                    redirect: "follow",
                  };

                  fetch("https://casaahaanahotels.com/get_random_assessement", requestOptions1)
                    .then((response) => {
                      if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                      }
                      return response.json();
                    })
                    .then((data) => {
                      if (data.driverbehaviour && data.driverskills) {
                        const mergedData = [];
                        const minLength = Math.min(
                          data.driverbehaviour.length,
                          data.driverskills.length
                        );

                        const assessmentIdNew = data.assessment_id;
                        localStorage.setItem("assessment_id", assessmentIdNew);

                        for (let i = 0; i < minLength; i++) {
                          const skillObj = data.driverskills[i];
                          const behaviorObj = data.driverbehaviour[i];

                          if (skillObj.skills && behaviorObj.behaviour) {
                            mergedData.push({
                              skill: skillObj.skills,
                              skillId: skillObj.id,
                              skillName: skillObj.name,
                              randomInteger: Math.floor(Math.random() * (1 - 2 + 2) + 2),
                              behavior: behaviorObj.behaviour,
                              behaviorId: behaviorObj.id,
                              behaviorName: behaviorObj.name,
                              randomInteger1: Math.floor(Math.random() * (3 - 1 + 1) + 1),
                            });
                          }
                        }
                        setFormData(mergedData);
                      } else {
                        console.error("Missing driverbehaviour or driverskills array in the API response.");
                      }
                    })
                    .catch((error) => {
                      console.error("An error occurred:", error);
                    });
                } else {
                  show_score();
                  setShowFirstSection(false);
                  setShowSecondSection(false);
                  setShowThirdSection(false);
                  setPageNo(highestPageNo);
                }

                if (highestPageNo >= 16) {
                  console.log(`The highest page number is ${highestPageNo}`);
                  showass();
                }
              }
            } else {
              const highestPageNo = Math.max(...result.map((data) => data.page_no));
              setPageNo(highestPageNo);
            }
          } else {
            setPageNo(1);
            const userId = localStorage.getItem("userid");
            const raw1 = JSON.stringify({
              Userid: userId,
              Assessementid: assessmentId,
              pageid: page_no,
            });

            const requestOptions1 = {
              method: "POST",
              headers: myHeaders,
              body: raw1,
              redirect: "follow",
            };

            fetch("https://casaahaanahotels.com/get_random_assessement", requestOptions1)
              .then((response) => {
                if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
              })
              .then((data) => {
                if (data.driverbehaviour && data.driverskills) {
                  const mergedData = [];
                  const minLength = Math.min(
                    data.driverbehaviour.length,
                    data.driverskills.length
                  );

                  const assessmentIdNew = data.assessment_id;
                  localStorage.setItem("assessment_id", assessmentIdNew);

                  for (let i = 0; i < minLength; i++) {
                    const skillObj = data.driverskills[i];
                    const behaviorObj = data.driverbehaviour[i];

                    if (skillObj.skills && behaviorObj.behaviour) {
                      mergedData.push({
                        skill: skillObj.skills,
                        skillId: skillObj.id,
                        skillName: skillObj.name,
                        randomInteger: Math.floor(Math.random() * (1 - 2 + 2) + 2),
                        behavior: behaviorObj.behaviour,
                        behaviorId: behaviorObj.id,
                        behaviorName: behaviorObj.name,
                        randomInteger1: Math.floor(Math.random() * (3 - 1 + 1) + 1),
                      });
                    }
                  }
                  setFormData(mergedData);
                } else {
                  console.error("Missing driverbehaviour or driverskills array in the API response.");
                }
              })
              .catch((error) => {
                console.error("An error occurred:", error);
              });
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    };

    const timeoutId = setTimeout(() => {
      fetchData1();
    }, 1000);

    // Clear the timeout if the component unmounts or if the effect is re-run
    return () => clearTimeout(timeoutId);

    buttonNameChange();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const assessment_id = localStorage.getItem('assessment_id');
    const userId = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const companycode = localStorage.getItem("reg") || "22c4693b-c80a-4785-82ef-08e0faa905b1";
    var raw = JSON.stringify({
      assessmentid: assessment_id,
      driver_id: userId,
      admincode: companycode,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://casaahaanahotels.com/sb_assessment_score", requestOptions)
      .then(response => response.text())
      .then(result => {
        const parsedResult = JSON.parse(result);
        const num_questions = parsedResult.num_questions;
        const sb_stars = parsedResult.sb_stars;
        // Fix: use the correct property name "assessment_date" from the API response
        const assessment_date = parsedResult.assessment_date;
        
        console.log(assessment_date)
        console.log(sb_stars);

        if (num_questions == 80) {
          setsbstars(sb_stars);
          localStorage.setItem("stars", sb_stars);
          localStorage.setItem("assessment_date", assessment_date);
          // set assessmentRecipient from the company_emails array if available
          if (parsedResult.company_emails && Array.isArray(parsedResult.company_emails)) {
            localStorage.setItem("assessmentRecipient", parsedResult.company_emails.join(','));
          }
        } else {
          localStorage.setItem("stars", 0);
          setsbstars(0);
        }
      })
      .catch(error => console.log('error', error));

    const fetchData = () => {
      if (page_no < 16) {
        if (page_no !== 1) {
          const page_no1 = page_no + 1;
          setPageNo(page_no1);
        }
      } else {
        show_score();
        setShowFirstSection(false);
        setShowSecondSection(false);
        setShowThirdSection(false);
        setShowfourSection(true);

        // Immediately reload the page 3 seconds after test is complete (showfourSection is true)
        setTimeout(function () {
         window.location.reload();
        }, 3000);
      }
    };

    const timeoutId = setTimeout(() => {
      fetchData();
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, []);

  const removecount = () => {
    localStorage.setItem('currentIndex', 0);
    localStorage.removeItem('assessment_id');
    window.location.reload();
  };

  function show_score() {
    const assessment_id = localStorage.getItem('assessment_id');
    const userId = localStorage.getItem("userid");
    const companycode = localStorage.getItem("reg") || "22c4693b-c80a-4785-82ef-08e0faa905b1";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      assessmentid: assessment_id,
      driver_id: userId,
      admincode: companycode,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://casaahaanahotels.com/sb_assessment_score", requestOptions)
      .then(response => response.text())
      .then(result => {
        const parsedResult = JSON.parse(result);
        const sb_stars = parsedResult.sb_stars;
        const num_questions = parsedResult.num_questions;
        // Fix: use the correct property name "assessment_date" instead of "assessement_date"
        const assessment_date = parsedResult.assessment_date;

        if (num_questions == 80) {
          setsbstars(sb_stars);
          localStorage.setItem("stars", sb_stars);
          localStorage.setItem("assessment_date", assessment_date);
        } else {
          localStorage.setItem("stars", 0);
          setsbstars(0);
        }
      })
      .catch(error => console.log('error', error));
  }

  const handleNextClick = () => {
    let newarray = [];
    const checkedRadios = document.querySelectorAll('input.valid:checked');

    if (checkedRadios.length > 0) {
      const checkedValuesArray = Array.from(checkedRadios).map((radio) => radio.value);
      const newSelectedItems = [...selectedItems];
      checkedValuesArray.slice(0, 5).forEach((value, index) => {
        newSelectedItems[index] = value;
      });
      newarray = newSelectedItems;
    } else {
      setValidationMessage('Please select at least one option from each set before proceeding.');
      return false;
    }

    const currentIndexlocal = localStorage.getItem('currentIndex');
    var newcurrentIndexlocal = parseInt(currentIndexlocal) + 5;
    if (newcurrentIndexlocal <= 75) {
      localStorage.setItem('currentIndex', newcurrentIndexlocal);
    } else if (currentIndexlocal == null) {
      localStorage.setItem('currentIndex', 5);
    }

    if (newarray.some(item => item === '')) {
      setValidationMessage('Please select at least one option from each set before proceeding.');
      return false;
    }

    if (newarray.some(item => item === undefined)) {
      setValidationMessage('Please select at least one option from each set before proceeding.');
      return false;
    } else {
      setCurrentIndex((prevIndex) => (prevIndex + setsToShow) % formData.length);
      setSelectedItems(Array(setsToShow).fill(undefined));
      setValidationMessage('');
      scrollToTop();

      const userid = localStorage.getItem('userid');
      const assessmentId = localStorage.getItem("assessment_id") || '';

      const assessmentData = {
        userid: userid,
        assessmentid: assessmentId,
        selectedItems: newarray,
        page_no: page_no,
        assessmentquestion: formData.slice(currentIndex, currentIndex + setsToShow).map((item, index) => {
          const isSkill = item.skillId === newarray[index];
          const isBehavior = item.behaviorId === newarray[index];
          const point = isSkill ? 1 : (isBehavior ? 5 : 0);

          return {
            Question: index + 1,
            Question_skill: item.skill,
            Skills: item.skillId,
            Question_behaviour: item.behavior,
            Behaviour: item.behaviorId,
            Answer: newarray[index],
            Point: point,
          };
        }),
      };

      const apiUrl = API_BASE_URL + "save_assessment";
      fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(assessmentData),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          console.log('API response:', data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }

    if (page_no < 16) {
      setPageNo(page_no + 1);
    } else {
      show_score();
      setShowFirstSection(false);
      setShowSecondSection(false);
      setShowThirdSection(false);
      setShowfourSection(true);
      // Immediately reload the page 3 seconds after test completion
      setTimeout(function () {
        window.location.reload();
      }, 3000);
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const userId = localStorage.getItem("userid");
    const assessmentId = localStorage.getItem("assessment_id");
    const page_id = page_no + 1;

    const raw = JSON.stringify({
      Userid: userId,
      Assessementid: assessmentId,
      pageid: page_id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://casaahaanahotels.com/get_random_assessement", requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.driverbehaviour && data.driverskills) {
          const mergedData = [];
          const minLength = Math.min(
            data.driverbehaviour.length,
            data.driverskills.length
          );

          const assessmentIdNew = data.assessment_id;
          localStorage.setItem("assessment_id", assessmentIdNew);

          for (let i = 0; i < minLength; i++) {
            const skillObj = data.driverskills[i];
            const behaviorObj = data.driverbehaviour[i];

            if (skillObj.skills && behaviorObj.behaviour) {
              mergedData.push({
                skill: skillObj.skills,
                skillId: skillObj.id,
                skillName: skillObj.name,
                randomInteger: Math.floor(Math.random() * (1 - 2 + 2) + 2),
                behavior: behaviorObj.behaviour,
                behaviorId: behaviorObj.id,
                behaviorName: behaviorObj.name,
                randomInteger1: Math.floor(Math.random() * (3 - 1 + 1) + 1),
              });
            }
          }
          setFormData(mergedData);
        } else {
          console.error("Missing driverbehaviour or driverskills array in the API response.");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const handleItemChange = (index, value) => {
    const newSelectedItems = [...selectedItems];
    newSelectedItems[index] = value;
    setSelectedItems(newSelectedItems);

    if (newSelectedItems.every(item => item !== undefined)) {
      setValidationMessage('');
    }
  };

  const handleProceedClick = () => {
    setShowFirstSection(false);
    setShowSecondSection(true);
    setShowThirdSection(false);
  };

  function showass() {
    show_score();
    setShowFirstSection(false);
    setShowSecondSection(false);
    setShowThirdSection(false);
    setShowfiveSection(true);
    setTimeout(function () {
      sendEmail();
      sendEmailnew();
    }, 3000);
  }

  const handleStartAssessmentClick = () => {
    setShowFirstSection(false);
    setShowSecondSection(false);
    setShowThirdSection(true);
    const currentIndexlocal = localStorage.getItem('currentIndex');
    if (page_no >= 16) {
      show_score();
      setShowFirstSection(false);
      setShowSecondSection(false);
      setShowThirdSection(false);
      setShowfiveSection(true);
    }

    const userid = localStorage.getItem('userid');
    const assessmentId = localStorage.getItem("assessment_id") || '';

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: userid,
      assessmentid: assessmentId,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch("https://casaahaanahotels.com/search_assessments", requestOptions)
      .then(response => response.json())
      .then(result => {
        const is_complete = result.is_complete;
        console.log(is_complete);
      })
      .catch(error => console.log('error', error));
  };

  const siteurl = localStorage.getItem('partnerurl');

  useEffect(() => {
    if (showfiveSection && countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    } else if (showfiveSection && countdown === 0) {
      window.location.href = siteurl;
    }
  }, [countdown, showfiveSection]);

  useEffect(() => {
    if (showfourSection && countdown1 > 0) {
      const timer = setTimeout(() => {
        setCountdown1(countdown1 - 1);
      }, 1000);

      if (countdown1 == 8) {
        if (window.innerWidth <= 768) {
         window.location.reload();
        }
      }
      return () => {
        clearTimeout(timer);
      };
    } else if (showfourSection && countdown1 === 0) {
      window.location.href = siteurl;
    }
  }, [countdown1, showfourSection]);

  const [assessmentDate, setAssessmentDate] = useState('');

  useEffect(() => {
    const getCurrentDate = () => {
      const date = new Date();
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
        .getDate()
        .toString()
        .padStart(2, '0')}/${date.getFullYear()}`;
    };

    const currentDate = getCurrentDate();
    setAssessmentDate(currentDate);
    localStorage.setItem("getCurrentDate", currentDate);

    const imageFile = localStorage.getItem("patnerImg");
    var existingImg = document.getElementById('bandingImg');
    if (!existingImg) {
      var img = document.createElement('img');
      img.src = 'https://starbehaviors.com/uploadimage/' + imageFile;
      img.width = 136;
      img.height = 48;
      img.alt = 'Star Behaviors Logo';
      img.id = 'bandingImg';

      var div = document.getElementById('banding');
      div.appendChild(img);
    }

    var div1 = document.getElementById('st');
    var oldImg = document.getElementById('imgId');
    if (div1 && oldImg) {
      div1.removeChild(oldImg);
    }
  }, []);

  // Keep track of how many times an error occurs
  let errorCount1 = 0;

  // Replacing old starbehaviors mail code with new Mailchimp API
  const sendEmail = async () => {
    show_score();
    const useremail = localStorage.getItem("useremail");
    const drivername = localStorage.getItem("drivername");
    const stars = localStorage.getItem("stars");

    if (!stars || stars === "0") {
      show_score();
      return false;
    }

    let sendmail;
    if (parseInt(stars, 10) >= 3) {
      // 3 or more
      sendmail= `<body style="font-family:Arial,sans-serif;background-color:#f4f4f4;margin:0;padding:0">
        <div class="container" style="width:96%;margin:30px auto;background:#fff;padding:20px;box-shadow:0 0 15px rgba(0,0,0,.2);border-radius:8px">
          <div class="header" style="text-align:center">
            <img src="https://starbehaviors.com/uploadimage/star_behaviors_llc_cover.jpeg" alt="Star Behaviors Logo" style="width:auto;max-width:auto;height:100px;display:block;margin:0 auto">
          </div>
          <div class="content" style="margin-top:20px;line-height:1.6">
            <h2 style="text-align:center">Assessment Completed - Congratulations on Your Achievement!</h2>
            <p style="font-size: 16px;">Dear ${drivername},</p>
            <p style="font-size: 16px;">We're thrilled to inform you that you've successfully completed [Part 1] of the Star Behaviors Assessment. Your journey towards a fulfilling career has taken a significant leap forward!</p>
            <center><h3>Your Star Score:</h3><img src="https://starbehaviors.com/uploadimage/${stars}.png"></center>
            <p style="font-size: 16px;">Your <b>${stars}-star rating</b>signifies your good alignment with the required behavioral traits for this position, indicating a high likelihood of long-term satisfaction and fulfillment in this career. Individuals receiving a ${stars}-star rating are normally well-suited for both formal (e.g., post-secondary training) and informal (on-the-job) training programs.</p>
            <p style="font-size: 16px;">Whether you're entering the job market for the first time, considering a career change, or already established in this field, you can confidently pursue this profession. Prospective employers are likely to be eager to explore training and employment opportunities with candidates of your caliber.</p>
            <h3>What This Means for Your Career</h3>
            <p style="font-size: 16px;">Receiving a ${stars}-star rating places you in an enviable position. It signifies that you're exceptionally well-suited for a range of professional development opportunities, encompassing both formal education (such as post-secondary training programs) and practical, on-the-job experiences.</p>
            <p style="font-size: 16px;">Whether you're stepping into the job market for the first time, contemplating a career shift, or furthering your journey in your current field, this score empowers you to confidently advance. Employers are always on the lookout for candidates of your caliber and potential, making you a desirable prospect for training and employment opportunities.</p>
            <p style="font-size: 16px;">At Star Behaviors, LLC, we take pride in guiding individuals towards careers that not only match their skills and interests but also ignite their passion. Thank you for trusting us on this pivotal journey. Remember, your success isn't just written in the stars—it's also shaped by your dedication and our support.</p>
            <p style="font-size: 16px;">Wishing you a stellar career ahead!</p>
            <p>Warm regards,</p>
            <p style="font-size: 16px;">TJ Graff<br>CEO<br>Star Behaviors, LLC</p>
          </div>
          <div class="footer" style="text-align:center;margin-top:20px;font-style:italic"><b>Powered by Star Behaviors.</b></div>
        </div>
      </body>`;
    } else {
      // <3
      sendmail = `<body style="font-family:Arial,sans-serif;background-color:#f4f4f4;margin:0;padding:0">
        <div class="container" style="width:96%;margin:30px auto;background:#fff;padding:20px;box-shadow:0 0 15px rgba(0,0,0,.2);border-radius:8px">
          <div class="header" style="text-align:center">
            <img src="https://starbehaviors.com/uploadimage/star_behaviors_llc_cover.jpeg" 
              alt="Star Behaviors Logo" 
              style="width:auto;max-width:100%;height:100px;display:block;margin:0 auto">
          </div>
          <div class="content" style="margin-top:20px;line-height:1.6">
            <h2 style="text-align:center">Assessment Completed</h2>
            <p style="font-size: 16px;">Dear ${drivername},</p>
            <p style="font-size: 16px;">
              We're thrilled to inform you that you've successfully completed <b>[Part 1]</b> of the Star Behaviors Assessment. 
              Your journey towards a fulfilling career has taken a significant leap forward!
            </p>
            <center>
              <h3>Your Star Score:</h3>
              <img src="https://starbehaviors.com/uploadimage/${stars}.png" 
                alt="Star Score" 
                style="display:block;margin:auto;max-width:100%;">
            </center>
            <p style="font-size: 16px;">
              The <b>Star Behaviors Behavioral Assessment</b> you've just completed is built upon an extensive job analysis 
              involving individuals who have achieved lasting success and contentment in this career. Our research has 
              identified numerous behaviors critical for your sustained happiness in this field.<br><br> 
              Research findings indicate that individuals scoring <b>${stars}</b> stars or lower are unlikely to experience 
              long-term fulfillment or happiness in this profession.
            </p>
            <p style="font-size: 16px;">
              Our partners are primarily interested in exploring training and employment opportunities with candidates 
              who score <b>3, 4, or 5 stars</b>. <br><br> 
              To ensure your enduring job satisfaction, we encourage you to consider completing one of our various 
              other compatibility self-assessments to find the career that is a perfect fit for you!
            </p>
            <p style="font-size: 16px;">
              Warm regards,<br><br>
              <b>TJ Graff</b><br> 
              CEO<br> 
              Star Behaviors, LLC
            </p>
          </div>
          <div class="footer" style="text-align:center;margin-top:20px;font-style:italic">
            <b>Powered by Star Behaviors.</b>
          </div>
        </div>
      </body>`;
    }

    try {
      // Build the request body for mailchimp
      const requestBody = {
        email: useremail,
        name: drivername,
        subject: 'Star Behaviors Assessment Results-User',
        message: sendmail
      };

      const mailApiUrl = 'https://bakemywords.com/mailchimp/sendmail.php';

      const response = await fetch(mailApiUrl, {
        method: 'POST',
        mode:'no-cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      });

      // parse the response
      const results = await response.json();
      console.log(results);

      if (results.status !== 'sent') {
        errorCount1++;
        if (errorCount1 === 3) {
          sendEmail();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  let errorCount = 0;
  const sendEmailnew = async () => {
    // If DefaultPage is true, do not send admin email to recipients.
    if (localStorage.getItem("DefaultPage") === "true") {
      return;
    }
    show_score();
    const stars = localStorage.getItem("stars");
    if (!stars || stars === "0") {
      show_score();
      return false;
    }
    // Some placeholders for building the email (for admin recipients)
    const useremail = localStorage.getItem("useremail");
    const drivername = localStorage.getItem("drivername");
    const driveraddress = localStorage.getItem("driveraddress") || "";
    const driverZip = localStorage.getItem("driverZip") || "";
    const driverphone = localStorage.getItem("driverphone") || "";
    const assessmentRecipient = localStorage.getItem("assessmentRecipient") || "";
    const getCurrentDate = localStorage.getItem("getCurrentDate") || "";
    const assessment_date = localStorage.getItem("assessment_date") || "";

    const sendmail= `<body style="font-family:Arial,sans-serif;background-color:#f4f4f4;margin:0;padding:0">
      <div class="container" style="width:96%;margin:30px auto;background:#fff;padding:20px;box-shadow:0 0 15px rgba(0,0,0,.2);border-radius:8px">
        <div class="header" style="text-align:center;margin-bottom:10px">
          <img src="https://starbehaviors.com/uploadimage/star_behaviors_llc_cover.jpeg" alt="Star Behaviors Logo" style="width:auto;height:100px">
        </div>
        <h2 style="text-align:center;margin-top:0">Star Behaviors Assessment Results</h2>
        <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
          <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Subject:</label>
          <span style="width:60%;text-align:left;font-size: 16px;">Star Behaviors Assessment Results</span>
        </div>
        <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
          <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">From:</label>
          <span style="width:60%;text-align:left;font-size: 16px;">admin@starbehaviors.com</span>
        </div>
        <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
          <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Date:</label>
          <span style="width:60%;text-align:left;font-size: 16px;">${getCurrentDate}</span>
        </div>
        <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
          <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">To:</label>
          <span style="width:60%;text-align:left;font-size: 16px;">${assessmentRecipient}</span>
        </div>
        <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
          <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Name:</label>
          <span style="width:60%;text-align:left;font-size: 16px;">${drivername}</span>
        </div>
        <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
          <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Address:</label>
          <span style="width:60%;text-align:left;font-size: 16px;">${driveraddress}</span>
        </div>
        <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
          <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Zip Code:</label>
          <span style="width:60%;text-align:left;font-size: 16px;">${driverZip}</span>
        </div>
        <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
          <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Phone Number:</label>
          <span style="width:60%;text-align:left;font-size: 16px;">${driverphone}</span>
        </div>
        <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
          <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Email:</label>
          <span style="width:60%;text-align:left;font-size: 16px;">${useremail}</span>
        </div>
        <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
          <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Assessment Completed:</label>
          <span style="width:60%;text-align:left;font-size: 16px;">${assessment_date}</span>
        </div>
        <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
          <label style="font-weight:700;width:30%;margin-right:5px;font-size: 16px;">Score:</label>
          <span style="width:70%;text-align:left;font-size: 16px;"><img src="https://starbehaviors.com/uploadimage/${stars}.png"></span>
        </div>
        <div class="powered" style="text-align:center;margin-top:40px;font-style:italic">Powered by Star Behaviors.</div>
      </div>
      </body>`;

    try {
      const mailApiUrl = 'https://bakemywords.com/mailchimp/sendmail.php';
      // Split the comma-separated recipient emails into an array
      const recipients = assessmentRecipient.split(',').map(email => email.trim()).filter(email => email.length > 0);
      console.log("Recipients:", recipients);
      for (const recipient of recipients) {
        const requestBody = {
          email: recipient,
          name: "Admin",
          subject: 'Star Behaviors Assessment Results-Admin',
          message: sendmail
        };
        console.log("Sending email to recipient:", recipient);
        const response = await fetch(mailApiUrl, {
          method: 'POST',
          mode: 'no-cors',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(requestBody)
        });
        // In no-cors mode, the response is opaque.
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      {showFirstSection && (
        <section>
          <div className="content">
            <div className="heading">
              <h2>Assessment - Instructions</h2>
            </div>
            <div className="main-content">
              <p>
                Here at <b>{companayname}</b>, we believe it is important for you, the professional driver,
                to have the opportunity to separate yourself apart from the "rest of the pack" and show
                that you have the Qualities, Core Values & Behaviors that we are looking for in "Top Performing Drivers".
              </p>
              <p>
                To apply for one of our world-class driving positions, please complete our online assessment and application.
              </p>
              <p>
                Once completed, a member of our Driver Recruiting Team will contact you shortly to outline
                the different opportunities available to you.
              </p>
            </div>
            <button className="button" onClick={handleProceedClick}>
              Proceed To Assessment
            </button>
          </div>
        </section>
      )}

      {showSecondSection && (
        <section>
          <div className="content">
            <div className="heading">
              <h2>Assessment - Instructions</h2>
            </div>
            <div className="main-content">
              <p>The assessment is broken into 5 questions per step.</p>
              <p>Please select the statement that you most closely identify with, for each of the pairings.</p>
              <p>Once all the steps are completed, please submit your answers by selecting SUBMIT.</p>
              <p>Please allow approximately 10 minutes to complete the assessment.</p>
            </div>
            <button className="button" onClick={handleStartAssessmentClick} id="pending">
              {buttonname}
            </button>
          </div>
        </section>
      )}

      {showThirdSection && (
        <section>
          <div className={styles.main_container}>
            <div className="container">
              <div className="position-relative d-flex flex-column justify-content-center align-items-center py-3 px-2">
                <div className="assessment-page">
                  <p>Page Number:-{page_no}</p>
                  <div className="card">
                    <h4 className="card-header text-uppercase">Assessment</h4>
                    <div className="card-body">
                      <form>
                        {formData.length > 0 && (
                          <div>
                            {formData.slice(currentIndex, currentIndex + setsToShow).map((item, index) => {
                              return (
                                <div key={index} className="card mb-3">
                                  <div className="card-body" style={{ maxWidth: '600px', display: 'flex', flexDirection: 'column' }}>
                                    <div className="form-check" style={{ order: item.randomInteger }}>
                                      <input
                                        className="form-check-input valid"
                                        type="radio"
                                        name={`selectedItem${index}`}
                                        id={`${item.skill}-radio`}
                                        value={item.skillId}
                                        valuename={item.skill}
                                        onChange={() => handleItemChange(index, item.skillId)}
                                        checked={selectedItems[index] === item.skillId}
                                        data-ans={item.skillName}
                                      />
                                      <label className="form-check-label" htmlFor={`${item.skill}-radio`}>
                                        {item.skill}
                                      </label>
                                    </div>
                                    <div className="form-check" style={{ order: item.randomInteger1 }}>
                                      <input
                                        className="form-check-input valid"
                                        type="radio"
                                        name={`selectedItem${index}`}
                                        id={`${item.behavior}-radio`}
                                        value={item.behaviorId}
                                        valuename={item.behaviour}
                                        onChange={() => handleItemChange(index, item.behaviorId)}
                                        checked={selectedItems[index] === item.behaviorId}
                                        data-ans={item.behaviorName}
                                      />
                                      <label className="form-check-label" htmlFor={`${item.behavior}-radio`}>
                                        {item.behavior}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}

                        {validationMessage && (
                          <div className="alert alert-danger" role="alert">
                            {validationMessage}
                          </div>
                        )}
                        <hr />
                        <div>
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleNextClick}
                          >
                            Next &gt;
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className={`btn btn-primary back-to-top ${showBackToTop ? 'active' : ''}`}
              onClick={scrollToTop}
            >
              <FontAwesomeIcon icon={faArrowUp} />
            </button>
          </div>
        </section>
      )}

      {showfourSection && (
        <section>
          <div className="content">
            <div className="heading">
              <h2>Assessment - Completed</h2>
            </div>
            <div className="main-content">
              <p>Congratulations and Thank You for completing Part 1 of our Two Part Application Process.</p>
              <br />
              {countdown1 > 0 && (
                <div className="alert alert-success" role="alert">
                  You will be redirected to the partner site
                </div>
              )}
              <p>Please complete the application and a member of our Driver Recruiting Team will be contacting you shortly to discuss the options, opportunities, and driving situations available to you</p>
              <br />
              <p>Thank you. We look forward to speaking with you shortly.</p>
            </div>
          </div>
        </section>
      )}

      {showfiveSection && (
        <section>
          <div className="content">
            <div className="heading">
              <h2>Assessment - Completed</h2>
            </div>
            <div className="main-content">
              <p>Congratulations</p>
              <p>
                You have already completed an assessment within the Star Behaviors network, within the past 12 months.
                <br />
                Please proceed to Step 2 in the application process.
              </p>

              {countdown > 0 && (
                <div className="alert alert-success" role="alert">
                  You will be redirected to the partner site in {countdown} seconds...
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default AssessmentForm;
